body {
  margin: 0% !important ;
  font-family: "Montserrat", sans-serif;
}

.main-button {
  width: 250px;
  height: 40px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  font-size: 16px;

  &:hover, &:focus {
    animation: colourTrans 6s infinite ease-in-out;
    color: white;
    border: none;
  }
}

.ghost-button {
  width: 250px;
  height: 40px;
  border: none;
  background-color: transparent;
  border-radius: 5px;
  font-size: 16px;

  /* background-color: white; */

  &:hover {
    /* border: 1px solid black; */
    background-color: lightgray;
  }
}

@keyframes colourTrans {
  0% {
    background-color: #005da4;
  }
  25% {
    background-color: #ed7725;
  }
  50% {
    background-color: #ca2026;
  }
  75% {
    background-color: #00ae4d;
  }
  100% {
    background-color: #005da4;
  }
}
