.loader-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.438);
  width: 100vw;
  height: 100vh;
}

.loader {
  max-width: 200px;
  max-height: 200px;
  background-color: white;
  border-radius: 50%;
}

.glow-effect {
  position: relative;
}

.glow-container {
  position: absolute;
  /* outline: 3px solid blue; */
  inset: -50px;
  width: calc(100% + 100px);
  height: calc(100% + 100px);
}

.glow-blur,
.glow-line {
  width: calc(100% - 110px);
  height: calc(100% - 110px);
  fill: transparent;
  stroke-dasharray : 500px 70px;
  /* transition: stroke-dashoffset 15000ms;  */
  animation: rotate-outer infinite 10s linear;

  /* animation: rotate infinite 2s linear; */

  /* border-radius: 50%; */
}

.glow-effect:hover .glow-blur,
.glow-effect:hover .glow-line {
  animation: rotate-outer infinite 5s linear;
}

.glow-line {
  /* animation: colourTrans 6s infinite ease-in-out; */

  stroke-width: 2px;
}

.glow-blur {
  filter: blur(10px);
  /* animation: colourTrans 6s infinite ease-in-out; */

  stroke-width: 10px;
}

.load-img {
  max-width: 200px;
  max-height: 200px;
  animation: rotate infinite 4s linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @keyframes rotate-outer { */
/* from {
      transform: rotate(0deg);
    } */
/* to {
} */
/* } */

@keyframes rotate-outer {
  0% {
    stroke: #005da4;
    stroke-dashoffset: 0px;
  }
  5% {
    stroke: #ed7725;
    stroke-dashoffset: 50px;
  }
  10% {
    stroke: #ca2026;
    stroke-dashoffset: 100px;
  }
  15% {
    stroke: #00ae4d;
    stroke-dashoffset: 150px;
  }
  20% {
    stroke-dashoffset: 200px;
    stroke: #005da4;
  }
  25% {
    stroke-dashoffset: 250px;
    stroke: #ed7725;
  }
  30% {
    stroke-dashoffset: 300px;
    stroke: #ca2026;
  }
  35% {
    stroke-dashoffset: 350px;
    stroke: #00ae4d;
  }
  40% {
    stroke-dashoffset: 400px;
    stroke: #005da4;
  }
  45% {
    stroke-dashoffset: 450px;
    stroke: #ed7725;
  }
  50% {
    stroke-dashoffset: 500px;
    stroke: #ca2026;
  }
  55% {
    stroke-dashoffset: 550px;
    stroke: #00ae4d;
  }
  60% {
    stroke-dashoffset: 600px;
    stroke: #005da4;
  }
  65% {
    stroke-dashoffset: 650px;
    stroke: #ed7725;
  }
  70% {
    stroke-dashoffset: 700px;
    stroke: #ca2026;
  }
  75% {
    stroke-dashoffset: 750px;
    stroke: #00ae4d;
  }
  80% {
    stroke-dashoffset: 800px;
    stroke: #005da4;
  }
  85% {
    stroke-dashoffset: 850px;
    stroke: #ed7725;
  }
  90% {
    stroke-dashoffset: 900px;
    stroke: #ca2026;
  }
  95% {
    stroke-dashoffset: 950px;
    stroke: #00ae4d;
  }
  100% {
    stroke-dashoffset: 1000px;
    stroke: #005da4;
  }
}
