.toast-wrapper {
  position: absolute;
  z-index: 980;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 10px 20px;
}

.toast-message {
  display: flex;
  width: min(80vw, 450px);
  align-items: center;
  justify-content: space-between;
  /* gap: 50px; */
  font-size: 12px;
  margin-top: 5px;
  padding: 8px;
  color: white;
  border-radius: 10px;
  animation: fadeInOut 8s linear;
}

.toast-message p {
  margin: 0;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}

.toast-close {
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-self: flex-start;
  margin: 2px;
}
