.car-slide-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.back-button{
    float: left;
    z-index: 500;
    position: absolute;
  }
  .back-button-mobile{
    position: relative !important;
  }


.desktop-video{
    width: 40vw;
    border-radius: 30px;
    background-color: white;
    
}
.desktop-video video{
    border-radius: 20px;
}
.mobile-video{
    width: 90vw;
    border-radius: 30px;
}

.video-container{
    border-radius: 50px;
    position: relative;
    
}

.video-container-mobile{
    /* border-radius: 50px; */
    position: relative;
    /* border: 1px solid grey; */
    height: 40vh;
    width: 92vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-overlay{
    position: absolute;
    border-radius: 30px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.19);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
}
.video-overlay-mobile{
    position: absolute;
    /* border-radius: 20px; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: rgba(0, 0, 0, 0.19); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
}

.play-icon{
    cursor: pointer;
}
.play-icon:hover{
    color: white;
}

.slide-head-container{
    text-align: center;
}

.slide-heading{
    font-size: 40px;
    margin: 10px 0;

}

.slide-sub-head{
    font-size: 30px;
    margin: 10px 0;
    padding-bottom: 30px;
    
}

.background-layout{
    z-index: -1;
    opacity: 30%;
}