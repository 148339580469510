.path-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100vh;
  margin-right: 50px;
  @media (max-width: 900px) {
    margin-right: 0;
  }
}

.path-aligner{
    @media (max-width:900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.mobile-button{
    top: -10px;
    max-width: 50px;
}

.path-heading {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 15px;


}

.liked-heart {
  display: flex;
  justify-content: center;
  align-items: center;
}

.path-desc {
  text-align: left;
  background: rgba(255, 255, 255, 0.824);
  border-radius: 15px;
  padding: 15px;
  max-height: 200px;
  margin-top: 10px;
  width: 95%;
  @media (max-width: 900px) {
    margin-top: 10px;
    padding: 10px;
    width: 80vw;
    max-height: 150px;
  }
}
.path-text {
  max-height: 200px;
  overflow-y: scroll;

  @media (max-width: 900px) {
        max-height: 150px;
  }
}

video {
  width: 100%;
  border-radius: 15px;
  @media (max-width: 900px) {
    border: none !important;
    width: 90vw;
  }
}

.video-container {
  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
  }
}

.related-paths {
  max-width: 100%;
}

.related-item {
  width: 150px;
  height: 75px;
  background-color: white;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.related-item-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.car-modal {
  background-color: rgba(0, 0, 0, 0.625);
  z-index: 700;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box {
  width: min(60vw, 600px);
  height: min(35vh, 500px);
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modal-text-container {
}

.modal-text {
  height: 100px;
  overflow-y: scroll;

  padding: 5px;
  width: min(55vw, 500px);
}

.modal-button {
  width: 80%;
  margin-bottom: 10px;
}
