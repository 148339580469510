.modal-layout {
  width: clamp(300px, 70vw, 800px);
}

.feedback-wrapper {
  overflow-x: hidden;
  max-height: 80vh;
  text-align: center;

  @media (max-width: 400px) {
    overflow-y: scroll;
  }
}

.feedback-content{
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedback-sub-heading{
  font-size: 25px !important;
  font-weight: bold !important;

  @media (max-width: 470px) {
    font-size: 18px !important;
  }

}

.feedback-sub-text{
  font-size: 18px !important;
  @media (max-width: 470px) {
    font-size: 16px !important;
  }
}

.fb-question-container{
  width: 80%;

}

.fb-button-container{
  display: flex;
  gap: 15px;
  padding-top: 15px ;
  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.rating-text{
  font-weight: 600;
}

