.car-wrapper {
  max-width: 100%;
}

.button-wrapper {
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

swiper-container::part(button-next) {
  border: 5px solid black;
  color: black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 10px;
  margin-right: 20px;
}

swiper-container::part(button-next):hover {
  background-color: black;
  color: white;
  /* border: 5px solid darkgray; */
}


swiper-container::part(button-prev) {
    border: 5px solid black;
    color: black;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 10px;
    margin-left: 20px;
  }
  
  swiper-container::part(button-prev):hover {
    background-color: black;
    color: white; 
    /* border: 5px solid darkgray; */
   }

   /* @media (min-width: 590px) { */
   swiper-container::part(pagination) {
        padding-top: 50px;
    }
   /* } */

  swiper-container::part(bullet) {

    width: 15px;
    height: 15px;
  }

  swiper-container::part(bullet-active) {

    width: 15px;
    height: 15px;
  }