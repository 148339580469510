.home-page-wrapper {
  /* text-align: center; */
  /* position: relative; */
  width: 100vw;
  height: 100vh;
  /* overflow: hidden; */
}

.background-layout {
  width: 100%;
  height: 100%;
  background: url(../../assets/cbc-backround.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  opacity: 50%;
}

.footer {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-lockup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.start-button {
  display: flex;
  height: 80px;
  width: 300px;
  border: 1px solid black;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}
