.rd-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
}
.result-heading {
  text-align: center;
  margin: 0 !important;
  padding-top: 20px;
  @media (max-width: 600px) {
    font-size: 20px !important;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: white; 
  border: none;
}
 
::-webkit-scrollbar-thumb {
  background: lightgray; 

}

::-webkit-scrollbar-thumb:hover {
  background: darkgray; 
}

.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 80%;
  justify-content: space-around;
}

.page-footer {
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  width: 100%;
}

.pf-content {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 30px;
    justify-content: space-around;

  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  margin-bottom: 20px;
    gap: 10px;
  }
}

.send-results-button{

    background-color: green;
    color: white;
    @media (max-width:1000px) {
        width: 80vw;
    }
}

.footer-logo{
    max-width: 20vw;
    @media (max-width:1000px) {
        max-width: 50vw;
    }
}
