.career-path-page {
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 900px) {
    position: relative;
  }
}

.career-desc {
  max-width: 35%;
  @media (max-width: 900px) {
    max-width: 100%;
    width: 100%;
    position: absolute;
    z-index: 600;
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
  }
}

.career-path-wrapper {
  width: 35%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.list-wrapper {
  width: 30vw;
  text-align: left;
  background: rgba(255, 255, 255, 0.824);
  border-radius: 30px;
  padding: 15px;
  @media (max-width: 950px) {
    width: 70vw;
    margin-top: 40px;
    max-height: 80vh;
    overflow-y: scroll;
  }
  @media (max-width: 450px) {
    width: 80vw;
  }
}

.career-item-wrapper {
  cursor: pointer;

  padding-left: 20px;
  margin: 5px;
  border-radius: 15px;
  @media (max-width: 600px) {
    padding-left: 10px;
    margin: 8px;
  }
}

.career-item {
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 15px;
    margin: 10px 0;
  }
}

.swipe-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 150px;
  margin: 10px;
}
.rel-car-path{
    /* @media (max-width: 900px) { */
        margin-top: 10px;
      /* } */
}
