.desc-container {
  width: 100vw;
  display: flex;
  height: 100vh;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
}



.desc-wrapper {
  max-width: clamp(300px, 40vw, 900px);
  height: fit-content;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  background: #fff;
  flex: 1;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 50px;
  margin-right: 50px;
}

.desc-head {
  font-size: 40px;
}
.back-button{
  float: left;
}
.desc-head-complete {
  font-size: 30px;
  text-align: center;
}

.green {
  color: green;
}
.red {
  color: red;
}
.purple {
  color: purple;
}
.orange {
  color: orange;
}
.yellow {
  color: rgb(235, 235, 0);
}
.blue {
  color: blue;
}

.desc-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.desc-button {
  flex: 1;
  padding: 8px 16px;
  font-size: 16px;
}
