.slide-container{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.slide-content-wrapper{
    
    width: min(80%, 800px); 
    /* height: fit-content; */
    justify-content: space-between;
    padding: 16px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    background: #fff;
    text-align: center;
    text-align: -webkit-center;
    margin-bottom: 200px;
    
    
}

.question-heading{
    font-size: 50px !important;
    margin: 15px 0;
    @media (max-height: 820px) {
        text-align: center;
        font-size: 35px !important;
        padding: 0 10px;
    }
}

.q-description{
    font-size: 2.5rem;
    padding: 0 30px;
    @media (max-height: 820px) {
        text-align: center;
        font-size: 18px;
    padding: 0 10px;

    }
}

.yes-no-container{
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: center;
   
}



.yes-no-button{
    border: none;
    background-color: transparent;
  

    &:hover, &:focus{
        animation: spinAn  0.5s ease-in-out;
        border: none;
    }
}

.yes-b{
    &:hover, &:focus{
       color: green;
    }
}

.no-b{
    &:hover, &:focus{
        color: red;
     }
}

@keyframes spinAn {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-50deg);
    }


    100% {
        transform: rotate(0deg);
    }
    
}

@keyframes colourChange {
    0% {
      color: #005da4;
    }
    25% {
      color: #ed7725;
    }
    50% {
      color: #ca2026;
    }
    75% {
      color: #00ae4d;
    }
    100% {
      color: #005da4;
    }
  }