.modal-layout {
 
    width: clamp(300px, 70vw, 800px);

  }

  .send-result-content{
    text-align: center;
    width: 80%;
  }

  .disabled-button{
    background-color: grey;
    color: white;
    animation: none !important;
    transform: none !important;
  }

  .send-button{
    background-color: green;
    color: white;
    
  }

  .button-frame{
    border: 1px solid black;
  }

  .sr-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    @media (max-width: 700px) {
        flex-direction: column;
    }
  }

  .sr-text-wrapper > :first-child {
    font-weight: bold;
  }