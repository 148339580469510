:root {
  --item-height: 15vh;
}

@media (max-width: 1000px) {
  :root {
    --item-height: 12vh;
  }
}

.category-item {
  width: clamp(250px, 80vw, 1000px);
  height: var(--item-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  box-shadow: 5px 3px 18px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.item-content {
  display: flex;
}
.bubble-wrapper {
  position: relative;
  width: auto;
  height: 100%;
}

.total-bubble {
  width: calc(var(--item-height) * 1.5);
  height: calc(var(--item-height) * 1.5);
  border-radius: 100%;
  position: absolute;
  transform: translate(-15%, -15%);
  box-shadow: 5px 0px 18px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.total-percent {
  /* height: var(--item-height); */
  color: white;
  font-size: 50px;
  font-weight: bold;
  @media (max-width: 1000px) {
    font-size: 30px;
  }
}

.career-cat {
  color: white;
  margin-left: 60px;
  font-weight: bold;
}

.cat-main {
  font-size: 40px;
  margin: 10px 20px;
}

.cat-sub {
  font-size: 30px;
  margin: 0;
}

@media (max-width: 600px) {
    .career-cat {
        margin-left: 60px;
      }
      
      .cat-main {
        font-size: 20px;
        margin: 0;
      }
      
      .cat-sub {
        font-size: 15px;
        margin: 0;
      }
      
}

.icon-wrapper {
  position: relative;
  width: auto;
  height: 100%;
}

.icon {
  height: calc(var(--item-height));
  width: calc(var(--item-height));
  position: absolute;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  transform: translate(30%, -50%);
  box-shadow: 5px 0px 18px 0px rgba(0, 0, 0, 0.5);
}

.icon-image{
    width: calc(var(--item-height)*0.6);
    height: calc(var(--item-height)*0.6);;
}
