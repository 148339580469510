.form-wrapper{
display: flex;
flex: 1;
flex-direction: column;
/* width: 60%; */
width: clamp(300px , 60%, 450px);
    /* height: clamp(300px , 30%, 600px);   */
}

.popia-text{
    color: grey;
    font-size: 14px;
    max-width: 520px;
    @media (max-height: 820px) {
        font-size: 12px;
    }
}

.sign-up-wrapper{
    @media (max-height:680px) {
        overflow-y: scroll;
        overflow-x: hidden;


    }   
}

.assessment-modal {
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 50%;
    max-height: 80vh;
    transform: translate(-50%, 50%);
   
  
    padding: 30px;
    border: 1px solid black;
    border-radius: 15px;
    background-color: rgba(255, 255, 255);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    @media (max-height: 820px) {
        max-height: 90vh;

    }
  }


.sign-up-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow-x: scroll; */
   

}
.code-head {
    font-size: 25px;
}

label{
    text-align: left;
    padding-bottom: 5px;
    font-size: 16px;
    @media (max-height: 820px) {
        font-size: 14px;
    }
}
input, select{
    width: 98%;
    margin-bottom: 10px;
    border-radius: 5px;
    border: solid 0.5px lightgray;
    background-color: white;
    font-size: 16px;
    @media (max-height: 820px) {
        font-size: 14px;
    }
}
select{
    height: 30px;
    @media (max-height: 820px) {
        height: 25px;
    }
}

input {
    padding-right: 0px;
    height: 26px;
    @media (max-height: 820px) {
        height: 19px;
    }
}

.button-container{
    display: flex;
    flex-direction: row;
    gap: 5px;
    @media (max-width:650px) {
        flex-direction: column;
        align-items: center;
    }
}

.no-code{
    font-size: 16px;
    width:  clamp(300px,33vw, 600px);
}

.home-back{
    position: absolute;
    top: 0;
}

.back-button{
    margin:  30px 0px 0px 10px;
    border: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    font-size: 20px;
    gap: 5px;
    justify-content: center;
    width: 200px;
    height: fit-content;
    @media (max-width: 520px) {
        width: 100px;
        margin:  10px 0px 0px 10px;
    }

    &:hover{
     
        color: white;
    }
}

.code-wrapper{
    /* width: clamp(320px , 50%, 800px); */
    /* height: clamp(300px , 30%, 600px); */
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   align-content: center;
   gap: 1vh;
    & input {
        width: 80%;
        height: 30px;
        font-size: 16px;
        margin-bottom: 20px ;
    }
}
.get-voucher-route{
    cursor: pointer;
    text-align: center;
}

.logo-image{
   width:  clamp(300px,40vw, 900px);
}

.error{
    text-align: right;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    color: red;

}