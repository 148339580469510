/* .swiper-container{
    width: 100%; */
    /* height: 100vh; */
/* }
.swiper-wrapper{
    width: 100vw;
    height: 100vh;
} */

:root{
    overflow-y: hidden;
}

.background-layout{
    position: fixed;
    width: 100%;
    height: 100%;
    background: url(../../assets/cbc-backround.png);
    background-repeat: no-repeat;
    background-size:cover;
    background-position: 50%;
    opacity: 50%;
  
  }

  .logos{
    width: 100%;
    position: absolute;
    bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-around;
    @media (max-width:480px) {
        flex-direction: column;
        align-items:center;
        gap: 5px;
        bottom: 5px;
    }
  }

  .sponsor-logo{
    min-width: 150px;
    width: 33vw;
    max-width: 450px;
  }